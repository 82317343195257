import type { RecordSourceOptions } from 'o365-dataobject';
import type { WorkerInitMessage, NodeDataConfigurationOptions } from './types.ts';
import type { WorkerMessageChannel as WorkerMessageChannelClass } from './worker.WorkerChannel.ts';

type ImportableScript = './worker.WorkerChannel.ts';
const ImportMaps = new Map<string, string>();

function importScript<T extends ImportableScript>(pName: T) {
    if (!ImportMaps.has(pName)) {
        throw new Error(`${pName} is not provided to the worker import maps`);
    }
    switch (pName) {
        case './worker.WorkerChannel.ts':
            return import(ImportMaps.get(pName)!) as unknown as Promise<typeof import('./worker.WorkerChannel.ts')>;
    }
    throw new Error(`${pName} is not importable in the worker`);
}

onmessage = (pEvent: MessageEvent<WorkerInitMessage>) => {
    if (pEvent.data.type !== 'init') { return; }
    for (const key in pEvent.data.importMaps) {
        ImportMaps.set(key, pEvent.data.importMaps[key])
    }

    init(pEvent.data.workerId, pEvent.ports[0]);
};

async function init(pId: string, pPort: MessagePort) {
    const { WorkerMessageChannel } = await importScript('./worker.WorkerChannel.ts');

    class NodeDataWorker {
        channel: WorkerMessageChannelClass;
        type: 'serverSide' | 'clientSide' = 'clientSide';

        configurations: NodeDataConfigurationOptions[] = [];

        structureFields: string[] = [];

        constructor(pOptions: {
            id: string,
            port: MessagePort
        }) {
            this.channel = new WorkerMessageChannel({
                id: pOptions.id,
                functions: {
                    'passConfigurations': (pConfigurations) => {
                        this.configurations = pConfigurations ?? [];
                        this.structureFields = ['OrgUnit', 'PrimKey'];
                        return Promise.resolve();
                    },
                    'retrieve': (pOptions) => {
                        if (pOptions?.type == 'clientSide') {
                            return this.clientSideRetrieve(pOptions.options);
                        } else {
                            return Promise.resolve([]);
                        }
                    }
                },
                port: pOptions.port
            });
        }

        async clientSideRetrieve(pOptions: RecordSourceOptions) {
            const result: any[] = [];
            pOptions.fields = this.structureFields.map((field) => ({ name: field }));
            const data = await workerFetch(pOptions, pOptions.dataSourceId!);
            debugger
            return result;
        }

        async getDataForClientSide() {

        }
    }

    const nodeDataWorker = new NodeDataWorker({
        id: pId,
        port: pPort
    });
}

function workerFetch(pRequestData: RecordSourceOptions, pDataObjectId: string) {
    const promise = fetch('/nt/api/data/' + pDataObjectId + '_NodeDataWorker', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            ...pRequestData,
            dataSourceId: pDataObjectId,
            operation: 'retrieve',
        })
    })
        .then((res) => res.json())
        .then((res) => {
            if (res.success) {
                return res.success;
            } else {
                throw res;
            }
        })

    return promise;
}